import React from "react"

import BaseLayout from 'layouts/base.layout'
import SEO from "components/seo"
import StageComponent from 'components/stage'
import ComponentLayout from 'layouts/component.layout'

const not_found = {
  stage: {
    title: 'Seite nicht gefunden'
  }
}

const NotFoundPage = (props) => (
  <BaseLayout>
    <SEO title="Seite nicht gefunden" url={ props.location.href } />
    <StageComponent props={ not_found.stage } />
    <svg className="wave-outer min-w-full" width="100%" height="100%" viewBox="0 0 6000 404">
      <path className="wave" d="M-0.001,535.333l6000,0l0,-352.756c-452.778,-120.385 -837.5,-180.577 -1154.17,-180.577c-1127.07,0 -1155.94,487.919 -1948.49,385.712c-227.895,-29.389 -568.681,-277.608 -980.678,-277.608c-447.397,0 -717.076,281.179 -1063.2,229.962c-409.912,-60.655 -527.655,-198.118 -853.463,-338.066l0,533.333Z"/>
    </svg>
    <div className="bg-white">
    <ComponentLayout className="grid-flow-col pb-38">
      <div className="col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-2 lg:col-end-12">
        <h2 className="text-3xl">Unfortunately we could not find the page you visited.</h2>
        <p>Did you maybe make a typo?</p>
      </div>
    </ComponentLayout>
    </div>
  </BaseLayout>
)

export default NotFoundPage
